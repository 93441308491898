import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/cashier/:qrcode/:channelId?",
    name: "CashierInfo",
    component: () => import('../views/CashierInfo.vue')
  },
  {
    path: "/doConsume/:orderNo/:channelId?",
    name: "DoConsume",
    component: () => import('../views/DoConsume.vue')
  },
  {
    path: "/bindOpenId/:merchId?",
    name: "BindOpenId",
    component: () => import('../views/BindOpenId.vue')
  },
  {
    path: "/debug/:action",
    name: "Debug",
    component: () => import('../views/Debug.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/other/404.vue')
  },
  {
    path: '/405',
    name: '405',
    component: () => import('../views/other/405.vue')
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_UR),
  routes
})

export default router
