import { createStore } from 'vuex'

export default createStore({
  state: {
    osTheme: 0,
  },
  mutations: {
    setOsTheme(state, osTheme: number) {
      state.osTheme = osTheme;
    },
  },
  actions: {
  },
  modules: {
  }
})