import { createApp, ref } from 'vue'
import App from './App.vue'
import router from './router'
import { ConfigProvider } from 'vant';
import store from './store';

import 'vant/es/toast/style';
import 'vant/es/dialog/style';

const darkMql = ref(window.matchMedia('(prefers-color-scheme: dark)'));
function handleDarkMqlChange(e: any) {
  if (e.matches) {
    store.commit("setOsTheme", 1);
  } else {
    store.commit("setOsTheme", 0);
  }
}
function init() {
  if (darkMql.value.matches) {
    store.commit("setOsTheme", 1);
  }
  else {
    store.commit("setOsTheme", 0);
  }
  if (darkMql.value.addEventListener) {
    darkMql.value.addEventListener('change', handleDarkMqlChange);
  }

  document.body.removeAttribute('style');
}
init();

createApp(App).use(router).use(ConfigProvider).mount('#app');